import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/pro-regular-svg-icons';
import { Message } from './Message';

interface IRetryProps {
    text: string;
    error: string;
    busy: boolean;
    onRetry: () => void;
}

export const Retry: React.FunctionComponent<IRetryProps> = (props) => {

    const spinner = props.busy ? <FontAwesomeIcon key="spinner" className="fa-spin" icon={faCircleNotch} style={{ marginRight: "0.5rem" }} /> : undefined;

    return (
        <div>
            <div className="block">
                <span className="translate">{props.text}</span>
            </div>
            <div className="block">
                <Message category="error" text={props.error} />
            </div>
            <div className="block">
                <button
                    id="retry"
                    className="button is-ghost is-medium"
                    disabled={props.busy}
                    onClick={
                        (e) => {
                            props.onRetry();
                        }}>

                    {spinner}<span className="translate">Erneut versuchen</span>
                </button>
            </div>
        </div>);
}