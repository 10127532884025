import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/pro-regular-svg-icons';

export interface IAuthButtonProps {
    text: string;
    busy: boolean; 
    disabled: boolean;
    onClick?: (e:any) => void;
}

export const AuthButton: React.FunctionComponent<IAuthButtonProps> = (props) => {

    let spinner: JSX.Element | undefined;
    if (props.busy) spinner = <FontAwesomeIcon className="fa-spin" icon={faCircleNotch} style={{marginRight: "0.5rem"}} />;

    return(
        <button id="authbutton" className="button is-primary is-medium" disabled={props.disabled} style={{width: "100%"}} onClick={
            (e) => {
                if (props.onClick) props.onClick(e);
            }}>

            {spinner}<span className="translate">{props.text}</span>
        </button>
    );
}