import React from 'react';
import {IModuleAssignment} from "@institutsitya/sitya-common/types/api/user";

export interface IModuleSelectorProps {
    mode: 'desktop' | 'mobile' | 'unknown';
    onSelectionChanged?: (course?: IModuleAssignment) => void;
    modules?: IModuleAssignment[];
    modulesSelection?: IModuleAssignment;
}

export const ModuleSelector: React.FunctionComponent<IModuleSelectorProps> = (props) => {

    const selectModule = (m?: IModuleAssignment) => {
        if (m !== props.modulesSelection) {
            if (props.onSelectionChanged) props.onSelectionChanged(m); 
        }
    }
    
    if (props.mode === 'desktop') {

        if (!props.modules?.length) return null;

        const items = props.modules?.map((m) => {
            return <li key={m.key} className={(m.key === props.modulesSelection?.key) ? "is-active" : ""} onClick={(e) => {
                selectModule(m);
            }}>
                <span className="tablink" style={{ fontWeight: (m.key === props.modulesSelection?.key) ? "bold" : "normal" }}>{m.name}</span>
            </li>;
        });

        return <div className="moduleselector tabs is-text is-centered is-fullwidth is-boxed is-right block" style={{ width: "100%", marginBottom: "1rem" }}>
            <ul>
                {items}
            </ul>
        </div>;
    }

    if (props.mode === 'mobile') {

        const items = props.modules?.map((m) => {
            return <option key={m.key} value={m.key}>{m.name}</option>;
        });

        if (!props.modules?.length) {
            return <div className="field block" style={{ marginTop: "1rem", marginBottom: "1rem" }}>
                <div className="control">
                    <div className="select is-medium" style={{ width: "100%" }}>
                        <select style={{width: "100%"}} disabled={true} value="no-modules">
                            <option value="no-modules" selected={true} disabled={true}>keine Module verfügbar</option>
                        </select>
                    </div>
                </div>
            </div>
        }

        return <div className="field block" style={{ marginTop: "1rem", marginBottom: "1rem" }}>
            <div className="control">
                <div className="select is-medium" style={{ width: "100%" }}>
                    <select style={{ width: "100%" }} onChange={(e) => {
                            selectModule(props.modules?.find((m) => m.key === e.target.value));
                        }}>
                        {items}
                    </select>
                </div>
            </div>
        </div>
    }

    return null;
}