import React, {useEffect, useState} from 'react';
import {BrowserRouter as Router, Redirect, Route, Switch} from "react-router-dom";

import * as auth from './controller/auth';
import * as id from './controller/identity';
import {IIdentity} from './controller/identity';
import redirection from './redirection';

import {Error} from './components/Error';
import {Navigation} from './components/Navigation';
import {Footer} from './components/Footer';
import {Login} from './views/auth/Login';
import {PasswordReset} from './views/auth/PasswordReset';
import {Infobar} from './components/Infobar';

import './style.scss';
import {Spinner} from './components/Spinner';

import Logout from './views/auth/Logout';
import DownloadView from './views/download/DownloadView';
import DashboardView from './views/dashboard/DashboardView';
import PasswordChange from './views/auth/PasswordChange';

function App() {

    const [initialized, setInitialized] = useState(false);

    const [showPasswordDialog, setShowPasswordDialog] = useState(false);
    const [identity, setIdentity] = useState<IIdentity | undefined>(undefined);

    useEffect(() => {

        // Listen on identity changes (login/logout/impersonate)
        // As this change triggers a render, also the routes (and therefore the deeplinking redirect) will be re-rendered
        const off1 = id.on('change', (err, data) => {
            setIdentity(data);
        });

        // Test if jwt is still valid before showing a login page
        auth.loginWithExistingToken()
            .then((systemuser) => setInitialized(true))
            .catch((error) => setInitialized(true));

        return () => off1();

    }, []);

    if (!initialized) return <Spinner text="SITYA E-Learning-Plattform wird initialisiert"/>;

    let dialog: JSX.Element | null = null;

    if (showPasswordDialog) dialog = <PasswordChange identity={identity} onClose={() => setShowPasswordDialog(false)}/>;

    return (

        <div className="App">

            <Router>
                <Infobar identity={identity}/>

                <Navigation
                    identity={identity}
                    onClickChangePassword={() => setShowPasswordDialog(true)}
                />

                <Switch>
                    <Route path="/passwort" component={PasswordReset}/>

                    <Route path="/login" render={() => {

                        // Triggered after login from change listener (see above)
                        if (identity) {
                            const target = redirection.getTarget();
                            if (target) redirection.setTarget(undefined);
                            return <Redirect to={target || '/'}/>
                        }
                        return <Login/>;
                    }}/>

                    <Route path="/logout" render={() => {
                        return identity ? <Logout/> : <Redirect to='/login'/>;
                    }}/>

                    <Route exact path="/kurse/:key?" render={(props) => {

                        if (!identity) {
                            redirection.setTarget(window.location.pathname);
                            return <Redirect to='/login'/>;
                        }
                        return <DownloadView {...props} />;
                    }}/>

                    <Route path="/index.html" render={() => {
                        return <Redirect to='/'/>;
                    }}/>

                    <Route exact path="/error/:code" render={(props) => {
                        return <Error code={props.match.params.code} {...props} />;
                    }}/>

                    <Route exact path="/" render={() => {
                        return identity ? <DashboardView/> : <Redirect to='/login'/>;
                    }}/>

                    <Route render={() => {
                        return <Redirect to='/error/404'/>;
                    }}/>

                </Switch>

                {dialog}
                <Footer identity={identity} />
            </Router>
        </div>);
}

export default App;
