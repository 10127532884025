import React, { useEffect, useState } from 'react';

export interface ISpinnerProps {
    text: string;
    delay?: number;
}

export const Spinner: React.FunctionComponent<ISpinnerProps> = (props) => {

    const [visible, setVisible] = useState(false);

    useEffect(() => {

        const delay = props.delay ?? 200;

        const timer = setTimeout(() => {
            setVisible(true);
        }, delay);

        return () => clearTimeout(timer);

    }, [props.delay]);

    if (!visible) return <div id="spinner"/>;

    return <div id="spinner">
        <div className="lds-ring">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
        <div className="hint" style={{fontSize: "1.2rem", padding: "1rem", marginBottom: "5%"}} >
            <span className="translate">{props.text}</span>
        </div>
    </div>;
}