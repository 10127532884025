import React from 'react';
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faBooks} from '@fortawesome/pro-duotone-svg-icons';
import {IStudyItem} from "@institutsitya/sitya-common/types/api/user";

export interface ICoursesProps {
    courses?: IStudyItem[];
}

export const CoursesSummary: React.FunctionComponent<ICoursesProps> = (props) => {
    const rows = props.courses?.map((c) => {
        return <div key={c.key}>
            <hr/>
            <div className="is-larger-text">
                <Link to={`/kurse/${c.key}`}>
                    <FontAwesomeIcon icon={faBooks} style={{marginRight: "0.5rem"}}/>
                    {c.name}
                </Link>
            </div>
        </div>;
    });

    return (
        <div>
            {rows}
        </div>);
}