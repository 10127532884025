import React from 'react';
import { RouteComponentProps } from 'react-router';

import { CourseSelector } from './CourseSelector';
import { FileList } from './FileList';
import { ModuleSelector } from './ModuleSelector';

import './DownloadView.scss';

import {faHourglassStart} from '@fortawesome/pro-light-svg-icons';
import * as student from '../../controller/student';
import { Message } from '../../components/Message';
import { Retry } from '../../components/Retry';
import {ICourseFileDownload, IModuleAssignment, IStudyItem} from "@institutsitya/sitya-common/types/api/user";
import {formatLongDate} from "../../misc/date";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

interface IDownloadViewState {

    mode: 'mobile' | 'desktop' | 'unknown';

    courses?: IStudyItem[];
    coursesBusy: boolean;
    coursesSelection?: IStudyItem;
    modulesSelection?: IModuleAssignment;
    coursesMessage?: string;

    files?: ICourseFileDownload[];
    filesBusy: boolean;
    filesMessage?: string;
}

interface IMatchParams {
    key: string;
}

interface IDownloadViewProps extends RouteComponentProps<IMatchParams> {
}

export default class DownloadView extends React.Component<IDownloadViewProps, IDownloadViewState> {

    public state: IDownloadViewState = {
        mode: "desktop",
        coursesBusy: true,
        filesBusy: false,
    }

    private ismounted = false;
    private unregister?: (() => void);

    public componentDidMount() {

        this.ismounted = true;

        window.scrollTo(0, 0);

        window.addEventListener('resize', this.onResize);
        this.onResize();

        const key = this.props.match.params.key;

        this.unregister = student.on('courses', (err, data) => {
            if (this.ismounted) {
                if (err) {
                    this.setState({ courses: undefined, coursesSelection: undefined, modulesSelection: undefined, coursesBusy: false, coursesMessage: err.message });

                } else {
                    let newselectedcourse =
                        (key ? data?.find((c) => c.key === key) : undefined) ||
                        (data ? data[0] : undefined);

                    this.setState({   
                        courses: data,
                        coursesBusy: false
                    });

                    this.onCourseChanged(newselectedcourse);
                }
            }
        });
    }

    // Code to handle back (and in-page links) button
    public componentDidUpdate() {
        
        const key = this.props.match.params.key;
        
        let course =
            (key ? this.state.courses?.find((c) => c.key === key) : undefined) ||
            (this.state.courses ? this.state.courses[0] : undefined);

        this.onCourseChanged(course);
    }

    public componentWillUnmount() {

        this.ismounted = false;

        window.removeEventListener('resize', this.onResize);

        if (this.unregister) this.unregister();
        this.unregister = undefined;
    }

    private onCourseChanged(c?: IStudyItem) {

        if (c !== this.state.coursesSelection) {

            // Update page url 
            const url = c ? `/kurse/${c.key}` : '/kurse';
            const current = this.props.history.location.pathname; 
            if (this.props.history.location.pathname !== url) {
            
                if (current === '/kurse' && c) this.props.history.replace(url);
                else this.props.history.push(url);
            }

            // Update page title
            document.title = c ? c.name + " | SITYA E-Learning" : "SITYA E-Learning";

            const m = c?.modules[0];

            this.setState({
                coursesSelection: c,
                modulesSelection: m
            });

            this.fetchFiles(c, m);
        }
    }

    private async fetchFiles(course?: IStudyItem, module?: IModuleAssignment) {

        if (!course || !module) {
            this.setState({ files: undefined, filesBusy: false });
            return;
        }

        try {

            this.setState({ filesBusy: true });

            const newfiles = await student.getFiles(course, module);
            
            this.setState({ files: newfiles, filesBusy: false });

        } catch (error: any) {

            this.setState({ filesBusy: false, filesMessage: error.message });
        }
    }

    private onResize = () => {

        if (this.ismounted) {
            const width = window.innerWidth;
            if ((width < 768) && (this.state.mode !== "mobile")) this.setState({ mode: "mobile" });
            if ((width >= 768) && (this.state.mode !== "desktop")) this.setState({ mode: "desktop" });
        }
    }

    private onModuleChanged(m?: IModuleAssignment) {

        this.setState({ modulesSelection: m });
        this.fetchFiles(this.state.coursesSelection, m);
    }

    private getCoursesSelector() {

        if (this.state.coursesMessage) return (
            <div style={{ marginTop: "1rem" }}>
                <Retry text="Ihre Kurse konnten nicht geladen werden"
                    error={this.state.coursesMessage}
                    onRetry={() => {
                        this.setState({ coursesMessage: "", coursesBusy: true });
                        student.queryCourses();
                    }}
                    busy={this.state.coursesBusy} />
            </div>);

        if (this.state.coursesBusy) return <Message text="Kurse werden geladen" category="busy"></Message>;

        return <CourseSelector
            mode={this.state.mode}
            courses={this.state.courses || []}
            coursesSelection={this.state.coursesSelection}
            onSelectionChanged={(c) => this.onCourseChanged(c)} />;
    }

    private getModuleSelector() {

        if (this.state.coursesBusy) return null;
        if (!this.state.courses || !this.state.courses.length) return <div><span className="translate">Kein Kurs zugeordet</span></div>;
        if (!this.state.coursesSelection) return <div><span className="translate">Kein Kurs ausgewählt</span></div>;

        return <ModuleSelector
            mode={this.state.mode}
            modules={this.state.coursesSelection?.modules || []}
            modulesSelection={this.state.modulesSelection}
            onSelectionChanged={(m) => this.onModuleChanged(m)} />;
    }

    private getFilesList() {

        if (!this.state.coursesSelection) return <div style={{marginTop: "1rem"}}>Kein Kurs gewählt</div>;

        if (this.state.filesMessage) return (
            <div style={{marginTop: "1rem"}}>
                <Retry text="Ihre Lehrunterlagen konnten nicht geladen werden"
                    error={this.state.filesMessage}
                    onRetry={() => this.fetchFiles(this.state.coursesSelection, this.state.modulesSelection)}
                    busy={this.state.coursesBusy} />
            </div>);

        if (this.state.coursesSelection?.code === "NOT_AVAILABLE" && this.state.coursesSelection?.earliestStart) {
            const courseStart = new Date(this.state.coursesSelection.earliestStart);
            return (
                <div style={{marginTop: "1rem"}}>
                    <FontAwesomeIcon icon={faHourglassStart} style={{marginRight: "0.5rem"}}/>
                    <span className="translate">Ihr Fernlehrgang startet erst am {formatLongDate(courseStart)}.</span>
                </div>
            );
        }

        if (this.state.filesBusy) return <Message text="Ihre Lehrunterlagen werden zusammengestellt" category="busy"></Message>;

        return <FileList files={this.state.files} modulekey={this.state.modulesSelection?.key}/>;
    }

    render() {

        const courses = this.getCoursesSelector();
        const modules = this.getModuleSelector();
        const files = this.getFilesList();

        return (
            <section className="downloadview container" style={{ paddingTop: "2rem", paddingBottom: "2rem" }}>
                <div className="columns is-desktop block">
                    <div className="column is-12 is-4-desktop">
                        <div className="card greenline" style={{ height: "100%" }}>
                            <div className="card-content">
                                <div className="is-header block"><span className="translate">Meine Kurse</span></div>
                                {courses}
                                {(this.state.mode === 'mobile') ? modules : null}
                            </div>
                        </div>
                    </div>
                    <div className="column is-12 is-8-desktop">
                        <div className="card greenline" style={{ height: "100%" }}>
                            <div className="card-content">
                                {(this.state.mode === 'desktop') ? modules : null}
                                {files}
                            </div>
                        </div>
                    </div>
                </div>
            </section>);
    }
}