import * as auth from './auth';
import { ISystemUser } from './auth';

type EventType = 'change';
type CallBackType = (err: string | null, data?: IIdentity) => void;
const listeners: CallBackType[] = [];

export interface IIdentity extends ISystemUser {
    impersonated: boolean;
}

let identity: IIdentity | undefined;

export function on(event: EventType, callback: CallBackType): (() => void) {
    
    listeners.push(callback);
    if (identity) callback(null, identity);

    return () => {
        const index = listeners.indexOf(callback);
        if (index >= 0) listeners.splice(index, 1);
    };
}

auth.on('change', (err, data) => {

    if (data) {

        let impersonated = false;

        if (data.role === "admin") {
            try {
                const params = new URLSearchParams(window.location.search);
                const user = params.get("user");
                if (user) {
                    const data = JSON.parse(window.atob(user));
                    if (data.uid && data.mail && data.name && data.role) {
                        impersonated = true;
                        identity = {
                            userid: data.uid,
                            username: data.mail,
                            name: data.name,
                            role: data.role,
                            impersonated: true
                        }
                    }
                }
            } catch (e) {
            }
        }

        if (!impersonated) {
            identity = {
                ...data,
                impersonated: false
            }
        }
    } else {
        identity = undefined;
    }
    
    listeners.forEach((callback) => callback(null, identity));
});

/*
export function setIdentity(userid: string, username: string, name: string, role: string) {

    identity = {
        userid: userid,
        username: username,
        name: name,
        role: role,
        impersonated: true
    }

    listeners.forEach((callback) => callback(null, identity));
}
*/

export function resetIdentity() {
    identity = {
        ...auth.getSystemUser()!,
        impersonated: false
    }

    listeners.forEach((callback) => callback(null, identity));
}

export function getIdentity(): IIdentity | undefined {
    return identity;
}

export function getSystemUser(): ISystemUser | undefined {
    return auth.getSystemUser();
}