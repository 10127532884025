import React from 'react';
import { FileItem } from './FileItem';
import {ICourseFileDownload} from "@institutsitya/sitya-common/types/api/user";

export interface IFilesListProps {
    files?: ICourseFileDownload[];
    modulekey?: string;
}

export const FileList: React.FunctionComponent<IFilesListProps> = (props) => {

    if (!props.files?.length) return <div>Keine Lehrunterlagen vorhanden</div>;

    const submodules: (string | undefined)[] = []; 

    // Get all submodules
    props.files.forEach((f) => {
        if (!submodules.includes(f?.submodule)) submodules.push(f?.submodule);
    });

    // Sort submodules
    submodules.sort();

    // Create rows, as we need the result to be structured by submodules
    const tables = submodules.map((s) => {

        // Get files for this submodule
        const files = props.files?.filter((f) =>  f.submodule === s);

        // Sort files
        files?.sort((a, b) => {
            const ca = a.type + a.name;
            const cb = b.type + b.name;
            if (ca > cb) return 1;
            if (ca < cb) return -1;
            return 0;
        });

        // Create rows
        const rows = files?.map((f) => {
            return <tr key={'file-' + f.name}>
                <td>
                    <FileItem file={f}/>
                </td>
            </tr>
        });

        const getHeader = () => {
            if (props.modulekey === 'B01') return 'Semesterprüfung';
            else if (props.modulekey === 'C01') return 'Abschlussprüfung';
            else return `Modul ${s}`;
        }

        // Embedd rows within a table
        return <table key={'submodule-' + s} className="table block is-narrow" style={{width: "100%"}}>
            <thead>
                <tr>
                    <th><span className="translate">{getHeader()}</span></th>
                </tr>
            </thead>
            <tbody>
                {rows}
            </tbody>
        </table>;
    });
    
    return <div>{tables}</div>;
}