import React from 'react';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {host} from '../../controller/communication';
import {ICourseFileDownload} from "@institutsitya/sitya-common/types/api/user";
import {FileType} from "@institutsitya/sitya-common/types/model/file";
import {faDownload} from "@fortawesome/pro-solid-svg-icons";
import {faExternalLinkAlt} from "@fortawesome/pro-solid-svg-icons";

export interface IFileItemProps {
    file: ICourseFileDownload;
}

export const FileItem: React.FunctionComponent<IFileItemProps> = (props) => {

    return (
        <a href={host + props.file.url} target="_blank" rel="noreferrer" className="fileitem is-block is-fullwidth">
            <span className="icon is-small">
                <FontAwesomeIcon icon={props.file.type === FileType.External ? faExternalLinkAlt : faDownload} className="fa-sm" />
            </span>
            <span className="ml-2">{props.file.name}</span>
        </a>);
}