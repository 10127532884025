import React from 'react';
import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/pro-light-svg-icons';

interface IErrorParams {
    code: string;
}

export const Error: React.FunctionComponent<IErrorParams> = (props) => {
    
    let navtarget = '/';
    let navtext = "Zur Startseite";
    let hint = 'Es ist ein technisches Problem aufgetreten. Bitte versuchen Sie es in ein paar Minuten erneut. Wenn das Problem weiterhin auftritt, wenden Sie sich bitte an unser Support Team.';

    switch (props.code) {

        case '401':
            hint = 'Sie sind nicht am Downloadbereich angemeldet. Bitte melden Sie sich an und versuchen Sie es erneut.';
            navtarget = '/login';
            navtext = 'Zum Login';
            break;

        case '403':
            hint = 'Sie sind nicht berechtigt die gewünschte Seite oder das gewünschte Dokument abzurufen. Dies kann passieren, weil Ihr Benutzer gesperrt wurde oder Sie für einen Kurs oder ein Modul nicht freigeschaltet sind.';
            break;

        case '404':
            hint = 'Das Dokument oder die Seite welche Sie abrufen möchten konnte nicht gefunden werden. Bitte überprüfen Sie Ihre Lesezeichen bzw. Ihre Eingabe. Wenn das Problem weiterhin auftritt, wenden Sie sich bitte an unser Support Team.';
            break;
    }

    return (<section id="error" className="container" style={{ paddingTop: "2rem", paddingBottom: "2rem" }}>
            <div className="greenline block card">
                <div className="card-content">
                    <div style={{fontSize: "3rem", marginTop: "1rem"}}>
                        <span>Uups...</span>
                    </div>
                    <div className="block" style={{fontSize: "1.5rem", marginTop: "1.5rem"}}>
                        <span className="translate">Wir bitten um Verzeihung, hier hat leider etwas nicht geklappt.</span>
                    </div>
                    <div className="block">
                        <span className="translate">{hint}</span>
                    </div>
                    <div className="block" style={{marginTop: "1.5rem"}}>
                        <span className="translate">Fehlercode: {props.code}</span>
                    </div>
                    <hr />
                    <div>
                        <Link to={navtarget} className="button is-medium is-primary mr-4">
                            <span className="translate">{navtext}</span>
                        </Link>
                        <a className="button is-medium is-text" href='mailto:support@institut-sitya.at'><FontAwesomeIcon className="mr-2" icon={faEnvelope}/><span>Lernhilfe &amp; Support</span></a>
                    </div>
                </div>
            </div>
    </section>);
}