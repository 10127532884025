import {getAccessToken} from "./auth";

export const host = "";

export async function post(endpoint: string, body?: any,) {
    return communicate('POST', endpoint, body);
}

export async function put(endpoint: string, body?: any,) {
    return communicate('PUT', endpoint, body);
}

export async function get(endpoint: string, body?: any,) {
    return communicate('GET', endpoint, body);
}

export async function patch(endpoint: string, body?: any,) {
    return communicate('PATCH', endpoint, body);
}

async function communicate(method: string, endpoint: string, body?: any) {

    const token = await getAccessToken();

    const headers = new Headers();
    headers.set('Content-Type', 'application/json');
    if (token) headers.set('Authorization', `Bearer ${token}`);

    const options: RequestInit = {method, headers};

    options.credentials = 'include';
    if (body) options.body = JSON.stringify(body);

    let code = "";

    try {

        const response = await fetch(host + endpoint, options);

        let content: any = null;

        try {
            if (response.status !== 204) content = await response.json();
        } catch (error) {}

        if ((response.status >= 200) && (response.status < 300)) return content;
        else {
            code = content?.error?.code;
            if (!code) code = `ERR_${response.status}`;
        }

    } catch (error) {

        code = "ERR_CONNECTION";
    }

    throw new Error(code);
}

