import * as api from './communication';
import * as id from './identity';
import { IIdentity } from './identity';
import {CourseAssignmentStyle} from "@institutsitya/sitya-common/types/model/user";
import {
    ICourseFileDownload,
    IGetUserResponse,
    IModuleAssignment,
    IStudyItem
} from "@institutsitya/sitya-common/types/api/user";

let coursesError: string | null = null;

let coursesData: IStudyItem[] | undefined;

let files: {[id: string]: ICourseFileDownload[]} = {};

export enum SumCourseStyle {
    WithSupport,
    WithoutSupport,
    Mixed,
    Unknown
}

type EventType = 'courses';
type CallBackType = (err: any, data?: IStudyItem[]) => void;

const onCourseListeners: CallBackType[] = [];

let identity: IIdentity | undefined;

id.on('change', (err, data) => {
    
    coursesError = null;
    coursesData = undefined;
    files = {};
    identity = data;

    // Notify subscribers about change (no courses/files available)
    emit('courses');

    // Query new course assignment, which emits the above as well when finished/failed
    if (identity) queryCourses();
});

export function on(event: EventType, callback: CallBackType): (() => void) {
    onCourseListeners.push(callback);
    if (coursesData) callback(null, coursesData);
    else if (coursesError) callback(coursesError);

    return () => {
        const index = onCourseListeners.indexOf(callback);
        if (index >= 0) onCourseListeners.splice(index, 1);
    };
}

function emit(event: EventType) {
    onCourseListeners.forEach((callback) => callback(coursesError, coursesData));
}

export function getCourses(): IStudyItem[] {
    return coursesData || [];
}

export function getHelpLink() {
    if (coursesData) {
        const style = getSumCourseStyle();
        if (style === SumCourseStyle.WithSupport) return "/private/Anleitung-detailliert-mit-Lernbegleitung.pdf";
        else if (style === SumCourseStyle.WithoutSupport) return "/private/Anleitung-detailliert-ohne-Lernbegleitung-Selbststudium.pdf";
    }
    return "/private/Anleitung-detailliert.pdf";
}

export function getSumCourseStyle() {

    let withSupport = 0;
    let withoutSupport = 0;

    coursesData?.forEach((c) => {
        if (c.style === CourseAssignmentStyle.WithoutSupport) withoutSupport++;
        else if (c.style === CourseAssignmentStyle.WithSupport) withSupport++;
    });

    if (withoutSupport && withSupport) return SumCourseStyle.Mixed;
    if (withoutSupport) return SumCourseStyle.WithoutSupport;
    if (withSupport) return SumCourseStyle.WithSupport;
    return SumCourseStyle.Unknown;
}

export async function queryCourses() {

    try {

        const userid = identity?.userid;
        const url = `/api/users/${userid}`;
        const response = await api.get(url) as IGetUserResponse;

        const courses = response.studyItems;
        coursesData = courses?.sort((a, b) => {
            if (a.name > b.name) return 1;
            if (a.name < b.name) return -1;
            return 0;
        });

        coursesError = null;
        emit('courses');

    } catch (error) {

        coursesData = undefined;
        coursesError = (error as any).message;
        emit('courses');
    }
}

export async function getFiles(course: IStudyItem, module: IModuleAssignment) {

    try {

        if (!course || !module) return undefined;
        
        const key = `${course.key}.${module.key}`;
        if (key in files) return files[key];

        const userid = identity?.userid;
        const url = `/api/users/${userid}/courses/${course.key}/${module.key}/files`;
        const response = await api.get(url);

        return files[key] = response as ICourseFileDownload[];

    } catch (error) {
        
        throw error;
    }
}