import React, { useState, useEffect } from 'react';
import * as identity from '../controller/identity';
import { Link } from "react-router-dom";

import { useLocation } from 'react-router-dom'

export interface INavigationViewProps {
    onClickChangePassword?: () => void;
    identity?: identity.IIdentity;
}

export const Navigation: React.FunctionComponent<INavigationViewProps> = (props) => {

    const [showNavigation, setShowNavigation] = useState(false);

    // Close burger on navigation
    let location = useLocation();
    useEffect(() => setShowNavigation(false), [location]);

    function toggleMenu(e: React.MouseEvent<HTMLButtonElement>) {
        setShowNavigation(!showNavigation);
    }

    const topnav: any[] = [];
    let button: JSX.Element | undefined;

    if (props.identity) {

        button = <button id="hamburger" className={`hamburger hamburger--elastic ${showNavigation ? "is-active" : ""}`} type="button" aria-label="Menu"
            aria-controls="navigation" aria-expanded="false" data-target="topnav" onClick={(e) => toggleMenu(e)}>

            <span style={{ marginRight: "0.5rem" }}>Men&uuml;</span>
            <span className="hamburger-box" style={{ marginBottom: "-6px" }}>
                <span className="hamburger-inner"></span>
            </span>
        </button>;

        topnav.push(
            <a key="item-1" href="https://www.institut-sitya.at/lernhilfe-und-support" target="_blank" rel="noreferrer noopener" className="navbar-item">
                Lernhilfe & Support
            </a>
        );

        // Only new users (with a valid mail-address can change their password)
        const chpwd = <span className="menulink link navbar-item translate" data-target="change-password-dialog"
                onClick={(e) => {
                    if (props.onClickChangePassword) props.onClickChangePassword();
                }}>
                Passwort &auml;ndern
            </span>;

        topnav.push(
            <div key="item-2" className="navbar-item has-dropdown is-hoverable">
                <span className="navbar-link is-text translate">
                    {props.identity.name}
                </span>
                <div className="navbar-dropdown">
                    {chpwd}
                    <Link to="/logout" className="navbar-item" ><span className="translate">Abmelden</span></Link>
                </div>
            </div>
        );

        if (props.identity.role === 'user') {

            topnav.push(
                <div key="item-3" className="navbar-item">
                    <Link to='/kurse'>
                        <button className="button is-medium is-primary">
                            <span className="translate">Meine Kurse</span>
                        </button>
                    </Link>
                </div>
            );
        }
    }

    return (
        <nav className="navbar" role="navigation" aria-label="main navigation">
            <div className="container">
                <div className="navbar-brand">
                    <Link to="/" className="navbar-item">
                        <img src="/logo-2023.svg" alt="Logo Institut SITYA" />
                    </Link>
                    {button}
                </div>

                <div id="topnav" className={`navbar-menu ${showNavigation ? "is-active" : ""}`} style={{ alignItems: "flex-end" }}>
                    <div className="navbar-end">
                        {topnav}
                    </div>
                </div>
            </div>
        </nav>
    );
}
