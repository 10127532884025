import React from 'react';
import { Welcome } from './Welcome';
import { Message } from '../../components/Message';
import * as student from '../../controller/student';
import './DashboardView.scss';
import { CoursesSummary } from './CoursesSummary';
import { Retry } from '../../components/Retry';
import * as id from '../../controller/identity';
import { IIdentity } from '../../controller/identity';
import {IStudyItem} from "@institutsitya/sitya-common/types/api/user";

interface IDashboardViewState {
    courses?: IStudyItem[];
    coursesBusy: boolean;
    coursesMessage: string;
    identity?: IIdentity;
}

export default class DashboardView extends React.Component<{}, IDashboardViewState> {

    public state: IDashboardViewState = {
        courses: [],
        coursesBusy: true,
        coursesMessage: "",
    }

    private off1?: (() => void);
    private off2?: (() => void);
    private ismounted = false;

    public componentDidMount() {

        this.ismounted = true;
        this.updatePageTitle();

        this.off1 = id.on('change', (err, data) => {
            if (this.ismounted) {
                this.setState({ identity: data });
                this.updatePageTitle(data);
            }
        });

        this.off2 = student.on('courses', (err, data) => {
            if (this.ismounted) {
                if (err) this.setState({ courses: undefined, coursesBusy: false, coursesMessage: err.message });
                else this.setState({ courses: data, coursesBusy: false });
            }
        });
        
        window.scrollTo(0, 0);
    }

    private updatePageTitle(identity?: IIdentity) {
        document.title = identity?.name + " | SITYA E-Learning";
    }

    public componentWillUnmount() {

        this.ismounted = false;

        if (this.off1) this.off1();
        if (this.off2) this.off2();

        this.off1 = undefined;
        this.off2 = undefined;
    }

    private getCoursesSummary() {

        if (this.state.coursesMessage) return (
            <div style={{ marginTop: "1rem" }}>
                <Retry text="Ihre Kurse konnten nicht geladen werden"
                    error={this.state.coursesMessage}
                    onRetry={() => {
                        this.setState({ coursesMessage: "", coursesBusy: true });
                        student.queryCourses();
                    }}
                    busy={this.state.coursesBusy} />
            </div>);

        if (this.state.coursesBusy) return <div style={{ marginTop: "1rem" }} ><Message text="Kurse werden geladen" category="busy"></Message></div>;
        return <CoursesSummary courses={this.state.courses} />;
    }

    render() {

        const divs: JSX.Element[] = [];

        divs.push(
            <div key="welcome" className={"column is-12 is-6-desktop"}>
                <div className="greenline block card" style={{ height: "100%" }}>
                    <div className="card-content">
                        <Welcome identity={this.state.identity} />
                    </div>
                </div>
            </div>
        );

        divs.push(
            <div key="courses" className="column is-12 is-6-desktop">
                <div className="greenline block card" style={{ height: "100%" }}>
                    <div className="card-content">
                        <div className="is-header"><span className="translate">Meine Kurse</span></div>
                        {this.getCoursesSummary()}
                    </div>
                </div>
            </div>
        );

        return (
            <section id="dashboard" className="container" style={{ paddingTop: "2rem", paddingBottom: "2rem" }}>
                <div className="columns is-desktop">
                    {divs}
                </div>
            </section>);
    }
}