import React from 'react';
import {IStudyItem} from "@institutsitya/sitya-common/types/api/user";

export interface ICourseSelectorProps {
    mode: 'desktop' | 'mobile' | 'unknown';
    onSelectionChanged?: (course?: IStudyItem) => void;
    courses?: IStudyItem[];
    coursesSelection?: IStudyItem;
}

export const CourseSelector: React.FunctionComponent<ICourseSelectorProps> = (props) => {

    let content: JSX.Element | null = null;

    const selectCourse = (c?: IStudyItem) => {
        if (c !== props.coursesSelection) {
            if (props.onSelectionChanged) props.onSelectionChanged(c);
        }
    }

    if (props.mode === 'desktop') {

        const rows = props.courses?.map((c) => {

            return <tr key={c.key} className="pointer">
                <td className={(c.key === props.coursesSelection?.key) ? "is-sitya-selected" : "is-sitya-not-selected"} onClick={(e) => {
                    selectCourse(c);
                }}>
                    <span className="ml-4" style={{display: "inline-block"}}>{c.name}</span>
                </td>
            </tr>;
        });

        if (rows && rows.length) {
            content = <table className="courseselector table is-fullwidth mb-4">
                <tbody>
                    {rows}
                </tbody>
            </table>;
        } else {
            content = <div className="mb-4"><span className="translate">Keine Kurse zugeordnet</span></div>;
        }
    }

    if (props.mode === 'mobile') {

        const rows = props.courses?.map((c) => {
            return <option key={c.key} value={c.key}>{c.name}</option>;
        });

        content = <div className="field block">
            <div className="control">
                <div className="select is-medium" style={{ width: "100%" }}>
                    <select
                        style={{ width: "100%" }}
                        value={props.coursesSelection?.key}
                        onChange={(e) => {
                            selectCourse(props.courses?.find((c) => c.key === e.target.value))
                        }}>
                        {rows}
                    </select>
                </div>
            </div>
        </div>
    }

    return (
        <div>
            {content}
        </div>);
}