import React from 'react';
import { Link, Redirect } from "react-router-dom";
import './Auth.scss'

import * as auth from '../../controller/auth';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/pro-solid-svg-icons';

import { AuthButton } from './AuthButton';
import { PasswordField } from './PasswordField';

import { Message } from '../../components/Message';
import {isStrongPassword} from "@institutsitya/sitya-common/misc/validator";
import {decode} from 'js-base64';

type PasswordResetChangeStateType = {
    username: string,
    password: string,
    countdown: number,
    step: 'init' | 'active' |  'failed' | 'finished',
    busy: boolean,
    redirect: string,
    message: string
}

export class PasswordResetChange extends React.Component<{ token: string }, PasswordResetChangeStateType> {

    private username: string | undefined;

    public state: PasswordResetChangeStateType = {
        step: 'init',
        countdown: 4,
        busy: false,
        message: '',
        redirect: '',
        username: '',
        password: '',
    };

    public componentDidMount() {

        try {
            const data = JSON.parse(decode(this.props.token));
            this.username = data?.username;
            if (this.username) this.setState({ username: this.username });

        } catch (err) {
            console.error(err);
            this.username = undefined;
        }

        this.verify();
    }

    private onKeyPressed(e: React.KeyboardEvent) {
        if (e.key === 'Enter') {
            e.preventDefault();
            this.change(e);
        }
    }

    private async verify() {
        if (this.username && this.props.token) {
            try {
                this.setState({ busy: true });

                await auth.resetPasswordVerify(this.username, this.props.token);
                this.setState({ step: 'active', busy: false });

            } catch (error: any) {
                this.setState({ message: error.message, step: 'failed', busy: false });
            }
        } else {
            this.setState({ message: "Der Link ist nicht gültig", step: 'failed', busy: false });
        }
    }

    private async change(e: any) {

        e.preventDefault();
        
        if (!isStrongPassword(this.state.password)) {
            this.setState({ message: 'Bitte wählen Sie ein starkes Passwort.'});
            return;
        }

        try {

            this.setState({ busy: true });
            await auth.resetPasswordSet(this.username!, this.props.token, this.state.password);

            this.setState({ busy: false, message: '', step: 'finished' });

            const id = setInterval(() => {

                if (this.state.countdown > 0) this.setState({ countdown: this.state.countdown - 1 });
                else {
                    clearInterval(id);
                    this.setState({ redirect: '/login' });
                }
            }, 1000);
        }
        catch (error: any) {
            this.setState({ message: error.message, busy: false });
        }
    }

    private getMessage() {
        let msg: JSX.Element | null = null;

        switch(this.state.step) { 
            case 'init':
                msg = <Message category="busy" text="Der Link wird überprüft"></Message>;
                break;

            case 'active':
            case 'failed':
                if (this.state.message) msg = <Message category="error" text={this.state.message}></Message>;
                break;

            case 'finished':
                msg = <Message category="success">
                    <span className="translate">Ihr Passwort wurde erfolgreich geändert. Sie werden in</span>
                    <span> {this.state.countdown+1} </span>
                    <span className="translate">Sekunden zur</span><span> </span>
                    <Link to='/login'>
                        <span className="translate">Anmeldung</span>
                    </Link>
                    <span> </span>
                    <span className="translate">weitergeleitet.</span>
                </Message>;
                break;
        }

        if (this.state.redirect) return <Redirect to={this.state.redirect} />;

        return msg;
    }

    private isDisabled(): boolean {
        return this.state.busy || (this.state.step !== 'active');
    }

    render() {

        const msg = this.getMessage();

        return (
            <form style={{ width: "100%" }}>
                <div className="is-header">Passwort zur&uuml;cksetzen</div>
                <div>Bitte geben Sie in das freie Feld "Neues Passwort" ein sicheres neues Passwort mit mindestens 8 Zeichen (zumindest jeweils ein Groß-/Kleinbuchstabe und eine Ziffer oder Sonderzeichen) ein.</div>
                <hr />

                <div className="control has-icons-left">
                    <input id="username" className="input block is-medium" type=" text" placeholder="E-Mail-Adresse"
                        disabled={this.isDisabled()} value={this.state.username} />
                    <span className="icon is-small is-left">
                        <FontAwesomeIcon icon={faEnvelope} />
                    </span>
                </div>

                <PasswordField 
                    disabled={this.isDisabled()} 
                    placeholder="Neues Passwort"
                    value={this.state.password}
                    onChange={(e: any) => this.setState({ ...this.state, password: e.target.value })}
                    onKeyPress={(e) => this.onKeyPressed(e)} >
                </PasswordField>
               
                <div className="block">
                    <AuthButton
                        busy={this.state.busy}
                        disabled={this.isDisabled()}
                        onClick={(e) => this.change(e)}
                        text="Passwort festlegen" />
                </div>

                <div className="block" style={{ height: "24px" }}>
                    {msg}
                </div>
            </form>
        );
    }
}