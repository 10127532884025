import { 
    IChangepwdRequest, 
    ILoginRequest, 
    IResetpwdInitRequest,
    IResetpwdVerifyRequest,
    IResetpwdSetRequest
} from '@institutsitya/sitya-common/types/api/auth';

import * as api from './communication';
import {host} from "./communication";

export interface ISystemUser {
    userid: string;
    username: string;
    name: string;
    role: string;
}

let systemuser: ISystemUser | undefined;

let initialized = false;

let accessToken: { token: string, ts: number, ttl: number, exp: number } | undefined;

type EventType = 'change';
type CallBackType = (err: string | null, data?: ISystemUser) => void;

export function getSystemUser() {
    return systemuser;
}

export function getSystemUserId() {
    return systemuser?.userid;
}

const onChangeListeners: CallBackType[] = [];
const onInitializeListeners: CallBackType[] = [];

export function on(event: EventType, callback: CallBackType): (() => void) {
    
    if (event === 'change') {
    
        onChangeListeners.push(callback);
        if (initialized) callback(null, systemuser);

        return () => {
            const index = onChangeListeners.indexOf(callback);
            if (index >= 0) onChangeListeners.splice(index, 1);
        };
    }

    else  {
    
        onInitializeListeners.push(callback);
        if (initialized) callback(null, systemuser);

        return () => {
            const index = onInitializeListeners.indexOf(callback);
            if (index >= 0) onInitializeListeners.splice(index, 1);
        };
    }
}

function emit(event: EventType) {
    if (event === 'change') onChangeListeners.forEach((callback) => callback(null, systemuser));
}

export function isLoggedIn(): boolean {
    if (systemuser) return true;
    return false;
}

export function isInitialized(): boolean {
    return initialized;
}

export async function resetPasswordInit(username: string) {

    const body: IResetpwdInitRequest = { 
        username: username
    };

    await post('/api/auth/reset/init', body);
}

export async function resetPasswordVerify(username: string, token: string) {

    const body: IResetpwdVerifyRequest = { 
        username: username,
        token: token
    };

    await post('/api/auth/reset/verify', body);
}

export async function resetPasswordSet(username: string, token: string, password: string) {

    const body: IResetpwdSetRequest = { 
        username: username.toLowerCase().trim(),
        password: password.trim(),
        token: token
    };

    await post('/api/auth/reset/set', body);
}

export async function changePassword(username: string, oldpassword: string, newpassword: string) {

    const body: IChangepwdRequest = {
        username: username.toLowerCase().trim(),
        oldpassword: oldpassword.trim(),
        newpassword: newpassword.trim()
    }

    await api.post('/api/auth/changepwd', body);
}

export async function loginWithCredentials(login: string, password: string, persist: boolean): Promise<ISystemUser> {

    const body: ILoginRequest = {
        username: login.toLowerCase().trim(),
        password: password.trim(),
        persist: persist
    }

    try {
        const response = await post('/api/auth/login/credentials', body) as any;

        systemuser = {
            username: response.user.mail,
            userid: response.user._id,
            name: response.user.name,
            role: response.user.role
        }

        processAccessToken(response.token);

        emit('change');
        return systemuser;

    } catch (error) {
        throw error;
    }
}

export async function loginWithExistingToken(): Promise<ISystemUser> {

    try {
        const response = await post('/api/auth/login/token') as any;

        systemuser = {
            username: response.user.mail,
            userid: response.user._id,
            name: response.user.name,
            role: response.user.role
        }

        processAccessToken(response.token);

        emit('change');
        return systemuser;

    } catch (error) {
        throw error;
    }
}

export async function getAccessToken() {
    try {

        let renew = true;
        if (accessToken) {

            renew = false;

            // Current timestamp in seconds
            const now = new Date().getTime() / 1000;

            // Check expiry timestamp of token
            if (accessToken.exp - now < 60) renew = true;

            // Check ttl based against local issue time to avoid clock-time issues
            if (accessToken.ts + accessToken.ttl - now < 60) renew = true;
        }

        if (renew) await renewAccessToken();

    } catch (e) {}

    return accessToken?.token;
}

function processAccessToken(token: string) {
    let valid = false;
    if (token) {
        try {
            const parts = token.split(".");
            if (parts.length === 3) {
                const decoded = JSON.parse(window.atob(parts[1]));
                const now = new Date().getTime() / 1000;
                valid = true;
                accessToken = {
                    token,
                    ttl: decoded.ttl || 600,
                    exp: decoded.exp,
                    ts: now
                }
            }
        } catch (e) {}
    }

    if (!valid) accessToken = undefined;
}

async function renewAccessToken(): Promise<void> {
    try {
        const response = await post('/api/auth/login/token') as any;
        processAccessToken(response.token);
    } catch (error) {
        throw error;
    }
}

export async function logout() {

    await api.post('/api/auth/logout');

    systemuser = undefined;
    accessToken = undefined;

    emit('change');
}

async function post(endpoint: string, body?: any) {

    const options: RequestInit = {
        method: "POST",
        headers: {
            'Content-Type': 'application/json'
        }
    };

    options.credentials = 'include';
    if (body) options.body = JSON.stringify(body);

    let code = "";

    try {

        const response = await fetch(host + endpoint, options);

        let content: any = null;

        try {
            if (response.status !== 204) content = await response.json();
        } catch (error) {}

        if ((response.status >= 200) && (response.status < 300)) return content;
        else {
            code = content?.error?.code;
            if (!code) code = `ERR_${response.status}`;
        }

    } catch (error) {

        code = "ERR_CONNECTION";
    }

    throw new Error(code);
}
