import React, {useEffect, useState} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faExclamationTriangle} from '@fortawesome/pro-regular-svg-icons';

import {IIdentity} from '../controller/identity';

interface IInfobarProps {
    identity?: IIdentity;
}

export const Infobar: React.FunctionComponent<IInfobarProps> = (props) => {

    const [visible, setVisible] = useState(false);
    const [message, setMessage] = useState("");

    useEffect(() => {

        if (props.identity && props.identity.impersonated) {
            setVisible(true);
            setMessage(`Sie haben die Identität von ${props.identity.name} angenommen.`);
        } else {
            setMessage('');
            setVisible(false);
        }

    }, [props.identity]);

    if (!visible) return null;

    return (<div style={{
        backgroundColor: "rgba(255,255,0,0.5)",
        color: "black",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    }}>
        <span style={{display: "flex", alignItems: "center"}}>
            <span className="mx-4">
                <span className="icon is-large">
                    <FontAwesomeIcon className="fa-lg" icon={faExclamationTriangle}/>
                </span>
            </span>
            <span className="translate">{message}</span>
        </span>
    </div>);
}




