import React from 'react';
import {Link} from "react-router-dom";

import './Auth.scss'

import * as auth from '../../controller/auth';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faEnvelope} from '@fortawesome/pro-solid-svg-icons';

import {AuthButton} from './AuthButton';
import {PasswordField} from './PasswordField';
import {Message} from '../../components/Message';

import qs from 'qs';

export class Login extends React.Component {

    public state = {
        username: '',
        password: '',
        busy: false,
        message: '',
        redirect: '',
        persist: 'on'
    }

    private ismounted = false;

    private async login(e: any) {

        e.preventDefault();
        this.setState({...this.state, busy: true});

        if (!this.state.username || !this.state.password) {
            this.setState({message: 'Bitte geben Sie Benutzername und Passwort an.', busy: false});
            return;
        }

        // After successful login the main routes are re-renderd, triggered by a change listener
        // within App.tsx
        try {
            await auth.loginWithCredentials(this.state.username, this.state.password, this.state.persist === 'on');
        } catch (error: any) {
            this.setState({message: error.message, busy: false});
        }
    }

    private onKeyPressed(e: React.KeyboardEvent) {
        if (e.key === 'Enter') {
            e.preventDefault();
            this.login(e);
        }
    }

    componentDidMount() {

        document.title = "SITYA E-Learning";
        this.ismounted = true;

        // Extract and process query string
        let urlparams = window.location.search;
        if (urlparams && urlparams.length && urlparams.charAt(0) === '?') urlparams = urlparams.substr(1);
        const parsed = qs.parse(urlparams);
        let username = parsed.name;
        if (username) this.setState({username: username});
    }

    componentWillUnmount() {
        this.ismounted = false;
    }

    adjustUsername(value: string): string {

        if (value.startsWith('mailto:')) return value.substr(7);
        return value;
    }

    render() {

        return (
            <section id="login" className="container">
                <div className="columns">
                    <div id="image" className="column is-6 is-visible-desktop">
                    </div>
                    <div className="column is-12-tablet is-6-desktop" style={{display: "flex", alignItems: "center"}}>
                        <form style={{width: "100%"}}>

                            <div className="is-header"><span className="translate">Login</span></div>
                            <div><span className="translate">Hier k&ouml;nnen Sie sich zu Ihren Kursen einloggen.</span>
                            </div>
                            <hr/>

                            <div className="control has-icons-left">
                                <input id="username" className="input block is-medium" type=" text"
                                       placeholder="E-Mail-Adresse"
                                       disabled={this.state.busy} value={this.state.username}
                                       onChange={(e: any) => this.setState({
                                           ...this.state,
                                           username: this.adjustUsername(e.target.value)
                                       })}
                                       onKeyPress={(e) => this.onKeyPressed(e)}/>
                                <span className="icon is-small is-left">
                                    <FontAwesomeIcon icon={faEnvelope}/>
                                </span>
                            </div>

                            <PasswordField
                                id="password"
                                disabled={this.state.busy}
                                value={this.state.password}
                                onChange={(e: any) => this.setState({...this.state, password: e.target.value})}
                                onKeyPress={(e) => this.onKeyPressed(e)}>
                            </PasswordField>

                            <div className="block" style={{marginTop: "-1.5rem"}}>
                                <div className="pretty p-default p-curve p-bigger">
                                    <input id="persist" type="checkbox" disabled={this.state.busy} tabIndex={-1}
                                           checked={this.state.persist === 'on'} onChange={(e: any) => this.setState({
                                        ...this.state,
                                        persist: (this.state.persist === 'on' ? 'off' : 'on')
                                    })}/>
                                    <div className="state p-primary">
                                        <label className="translate">Angemeldet bleiben</label>
                                    </div>
                                </div>
                            </div>

                            <div className="block">
                                <Link id="forgot" to="/passwort" tabIndex={-1}><span className="translate">Passwort vergessen?</span></Link>
                            </div>

                            <div className="block">
                                <AuthButton busy={this.state.busy} disabled={this.state.busy}
                                            onClick={(e) => this.login(e)} text="Anmelden"/>
                            </div>

                            <div className="block" style={{height: "24px"}}>
                                {(this.state.message) ?
                                    <Message text={this.state.message} category="error"></Message> : null}
                            </div>
                        </form>
                    </div>
                </div>
            </section>
        );
    }
}
