import React from 'react';
import { useLocation } from "react-router-dom";
import './Auth.scss'

import { PasswordResetChange } from './PasswordResetChange';
import { PasswordResetInit } from './PasswordResetInit';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

export const PasswordReset: React.FunctionComponent = () => {

    let query = useQuery();
    let token = query.get('token');

    const content = token ? <PasswordResetChange token={token} /> : <PasswordResetInit />;

    return (
        <section id="login" className="container">
            <div className="columns">
                <div id="image" className="column is-6 is-visible-desktop">
                </div>
                <div className="column is-12-tablet is-6-desktop" style={{ display: "flex", alignItems: "center" }} >
                    {content}
                </div>
            </div>
        </section>
    );
}
