import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSmile} from '@fortawesome/pro-light-svg-icons';
import {faUserGraduate} from '@fortawesome/pro-duotone-svg-icons';
import {IIdentity} from '../../controller/identity';

export interface IWelcomeProps {
    identity?: IIdentity;
}

export const Welcome: React.FunctionComponent<IWelcomeProps> = (props) => {

    let icon: JSX.Element | undefined;
    let funnyhint: JSX.Element | undefined;
    let wisewords: JSX.Element | undefined;

    const getWiseWords = () => {
        return (
            <div className="translate">
                Sie befinden sich nun auf der interaktiven E-Learning-Plattform SITYA.
                Diese bietet Ihnen den Zugriff zu Ihren gebuchten Fernlehrgängen mit allen Informationen, Anleitungen,
                Schulungsunterlagen sowie Prüfungen als Download.<br/><br/>
                Bei Fragen können Sie uns gerne jederzeit über unser Formular <a href="https://share.hsforms.com/1pgb0ukp6QmKLMMPMvjGZKg3hhdt" target="_blank" rel="noreferrer noopener">Lernhilfe & Support</a> erreichen.<br/><br/>
                Viel Freude beim Lernen wünscht Ihr SITYA Team!
            </div>
        );
    }

    if (!props.identity) return null;

    const date = new Date();
    const hours = date.getHours();

    let greeting = "";
    if ((hours >= 21) || (hours < 3)) greeting = "Gute Nacht";
    else if (hours >= 17) greeting = "Guten Abend";
    else if (hours >= 9) greeting = "Guten Tag";
    else if (hours >= 3) greeting = "Guten Morgen";

    let funnytext = "";

    wisewords = <div>{getWiseWords()}</div>;
    if ((hours > 21) || (hours <= 3)) funnytext = " Sie lernen heute spät";

    icon = <figure id="avatar-container" className="image" style={{display: "flex", alignItems: "center"}}>
        <FontAwesomeIcon id="avatar" icon={faUserGraduate}/>
    </figure>;

    if (funnytext) {
        funnyhint = (
            <div className="block is-sub-header">
                <span>
                    {funnytext}
                    <FontAwesomeIcon icon={faSmile} style={{marginLeft: "0.5rem"}}/>
                </span>
            </div>
        );
    }

    return (

        <div>
            <div id="welcome" className="block" style={{display: "flex", alignItems: "center"}}>
                {icon}
                <div id="name">
                    <div className="is-header"><span className="translate">{greeting}</span>,</div>
                    <div className="is-sub-header"><span>{props.identity.name}</span></div>
                </div>
            </div>
            <hr/>
            {funnyhint}
            <div className="block is-larger-text">
                {wisewords}
            </div>
        </div>
    );
}
