import React from 'react';
import { Redirect } from "react-router-dom";

import './Auth.scss'

import redirection from '../../redirection';
import * as auth from '../../controller/auth';

import { Message } from '../../components/Message';

export default class Logout extends React.Component {

    public state = {
        busy: false,
        message: '',
        redirect: '',
    }

    private ismounted = false;

    private async logout() {

        try {

            this.setState({ busy: true, message: '' });
            redirection.setTarget(undefined);
            
            await auth.logout();
            
            if (this.ismounted) this.setState({ busy: false, message: '', redirect: '/login' });

        } catch (error: any) {
            if (this.ismounted) this.setState({ busy: false, message: error.message });   
        }
    }

    componentDidMount() {
        
        document.title = "SITYA E-Learning";
        
        this.ismounted = true;
        this.logout();
    }

    componentWillUnmount() {
        this.ismounted = false;
    }

    render() {

        if (this.state.redirect.length > 0) {

            redirection.setTarget(undefined);
            return <Redirect to={this.state.redirect} />;
        }

        let msg: JSX.Element | null = null;

        if (this.state.busy) {
            msg = <Message category="busy" text="Sie werden abgemeldet"></Message>;
        };

        if (!this.state.busy || this.state.message) {
            msg = <Message category="error" text={this.state.message}></Message>;
        };

        return (
            <div className="container">
                {msg}
            </div>
        );
    }
}
