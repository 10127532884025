import React from 'react';
import './Auth.scss'

import * as auth from '../../controller/auth';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/pro-solid-svg-icons';
import { AuthButton } from './AuthButton';

import { Message } from '../../components/Message';
import {isValidEMail} from "@institutsitya/sitya-common/misc/validator";

type PasswordResetInitStateType = {
    username: string,
    step: 'active' | 'finished',
    busy: boolean,
    redirect: string,
    message: string
}

export class PasswordResetInit extends React.Component<{}, PasswordResetInitStateType> {

    public state: PasswordResetInitStateType = {
        username: '',
        step: 'active',
        busy: false,
        redirect: '',
        message: '',
    }

    private onKeyPressed(e: React.KeyboardEvent) {
        if (e.key === 'Enter') {
            e.preventDefault();
            this.requestlink(e);
        }
    }

    private async requestlink(e: any) {

        e.preventDefault();

        if (!isValidEMail(this.state.username)) {
            this.setState({ message: 'Bitte geben Sie eine gültige E-Mail-Adresse an.' });
            return;
        }

        try {

            this.setState({ ...this.state, busy: true });

            await auth.resetPasswordInit(this.state.username);
            this.setState({ message: '', step: 'finished', busy: false });
            return;
        
        } catch (error: any) {
            this.setState({ message: error.message, busy: false });
        };
    }

    private isDisabled(): boolean {
        return this.state.busy || (this.state.step !== 'active');
    }

    render() {

        let msg: JSX.Element | null = null;

        if (this.state.step === 'finished') {
            msg = <Message category="success">
                <span className="translate">Es wurde ein Mail mit einem Link zum Zurücksetzen Ihres Passwortes an </span>
                <span>{this.state.username}</span>
                <span className="translate"> gesendet. Bitte befolgen Sie die Anleitungen in der Mail. Sie können dieses Fenster nun schliessen.</span>
                </Message>;
        } else {
            if (this.state.message) {
                msg = <Message category="error" text={this.state.message}></Message>;
            }
        }

        return (
            <form style={{ width: "100%" }}>
                <div className="is-header"><span className="translate">Passwort vergessen?</span></div>
                <div>
                    <span className="translate">
                        Bitte geben Sie Ihre E-Mailadresse (E-Mail-Adresse aus Kursbuchung) in das freie Feld ein. Sie erhalten umgehend den Link per E-Mail zum Passwort zurücksetzen.
                    </span>
                </div>
                <hr />

                <div className="control has-icons-left">
                    <input className="input block is-medium" type=" text" placeholder="E-Mail-Adresse"
                        disabled={this.isDisabled()} value={this.state.username} onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.setState({ username: e.target.value })}
                        onKeyPress={(e) => this.onKeyPressed(e)} />
                    <span className="icon is-small is-left">
                        <FontAwesomeIcon icon={faEnvelope} />
                    </span>
                </div>

                <div className="block">

                    <AuthButton
                        busy={this.state.busy}
                        disabled={this.isDisabled()}
                        onClick={(e) => this.requestlink(e)}
                        text="Passwort festlegen" />
                </div>

                <div className="block" style={{ height: "24px" }}>
                    {msg}
                </div>
            </form>
        );
    }
}