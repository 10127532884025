import React from 'react';
import * as auth from '../../controller/auth';
import { Message } from '../../components/Message';
import { PasswordField } from './PasswordField';

import {IIdentity} from './../../controller/identity';
import {isStrongPassword} from "@institutsitya/sitya-common/misc/validator";

interface IPasswordChangeProps {
    onClose?: () => void;
    identity?: IIdentity;
}

interface IPasswordChangeState {

    oldpassword: string;
    newpassword: string;
    busy: boolean;
    message?: string;

    countdown?: number;
    finished: boolean;
}

export default class PasswordChange extends React.Component<IPasswordChangeProps, IPasswordChangeState> {

    public state: IPasswordChangeState = {

        busy: false,
        finished: false,
        message: '',
        oldpassword: '',
        newpassword: '',
    };

    private ismounted = false;
    private timer: NodeJS.Timeout | undefined;

    private closeDialog() {
        if (this.props.onClose) this.props.onClose();
    }

    public componentDidMount() {
        this.ismounted = true;
    }

    public componentWillUnmount() {

        this.ismounted = false;

        if (this.timer) clearInterval(this.timer);
        this.timer = undefined;
    }

    private async change() {

        if (this.state.finished) return this.closeDialog();

        if (!this.state.oldpassword) {
            this.setState({ message: 'Bitte geben Sie Ihr altes Passwort ein.'});
            return;
        }

        if (!this.props.identity) {
            this.setState({ message: 'Bitte melden Sie sich erneut an.'});
            return;
        }

        if (!isStrongPassword(this.state.newpassword)) {
            this.setState({ message: 'Bitte wählen Sie ein starkes Passwort.'});
            return;
        }
        
        try {

            this.setState({ busy: true });
            await auth.changePassword(this.props.identity.username, this.state.oldpassword, this.state.newpassword);

            this.setState({ busy: false, message: '', finished: true, countdown: 3 });

            this.timer = setInterval(() => {
                if (this.ismounted) {
                    const val = this.state.countdown || 0;
                    if (val > 1) this.setState({countdown: this.state.countdown! - 1});
                    else this.closeDialog();
                }
            }, 1000);
        
        } catch (error: any) {
            this.setState({ message: error.message, busy: false });
        };
    }

    private onKeyPressed(e: React.KeyboardEvent) {
        if (e.key === 'Enter') e.preventDefault();
    }

    render() {
        
        let msg: JSX.Element | null = null;
        if (this.state.busy) msg = <Message category="busy" text="Ihr Passwort wird geändert"></Message>;
        else if (this.state.finished) msg = <Message category="success">
                <span className="translate">Passwort geändert. Das Fenster wird in</span>
                <span> {this.state.countdown} </span>
                <span className="translate">Sekunden geschlossen.</span>
            </Message>;
        else if (this.state.message) msg = <Message category="error" text={this.state.message}></Message>;

        return (
            <div id="change-password-dialog" className='modal is-active'>
                <div className="modal-background" onClick={() => this.closeDialog()}></div>
                <div className="modal-card">
                    <header className="modal-card-head">
                        <p className="modal-card-title"><span className="translate">Passwort &auml;ndern</span></p>
                        <button className="delete" aria-label="close" onClick={() => this.closeDialog()}></button>
                    </header>
                    <section className="modal-card-body">

                        <form target="#" style={{ padding: "1rem" }}>
                            <div className="block">
                                <span className="translate">Bitte vergeben Sie ein sicheres neues Passwort mit mindestens 8 Zeichen (zumindest jeweils ein Groß-/Kleinbuchstabe und eine Ziffer bzw. Sonderzeichen).</span>
                            </div>

                            <PasswordField
                                id="old-password"
                                placeholder="Altes Passwort"
                                onKeyPress={(e) => this.onKeyPressed(e)}
                                disabled={this.state.busy}
                                value={this.state.oldpassword}
                                onChange={(e: any) => this.setState({ ...this.state, oldpassword: e.target.value })} >
                            </PasswordField>

                            <PasswordField
                                id="new-password"
                                placeholder="Neues Passwort"
                                onKeyPress={(e) => this.onKeyPressed(e)}
                                disabled={this.state.busy}
                                value={this.state.newpassword}
                                onChange={(e: any) => this.setState({ ...this.state, newpassword: e.target.value })} >
                            </PasswordField>

                            <div className="block" style={{height: "24px"}}>
                                {msg}
                            </div>

                        </form>
                    </section>
                    <footer className="modal-card-foot is-justify-content-flex-end">
                        <button id="buttoncancel" disabled={this.state.busy} className="button is-medium is-text" tabIndex={-1}
                            onClick={() => this.closeDialog()}><span>Abbrechen</span></button>
                        <button id="buttonchange" disabled={this.state.busy} className="button is-primary is-medium" onClick={() => this.change()}>
                            <span>{this.state.finished ? "Schliessen" : "Passwort ändern"}</span>
                        </button>
                    </footer>
                </div>
            </div>
        );
    }
}
