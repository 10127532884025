export interface IRedirection {
    setTarget(target?: string): void;
    getTarget(): string | undefined;
}

export class Redirection implements IRedirection {

    private target?: string;

    public setTarget(target?: string) {
        this.target = target;
    }

    public getTarget() {
        return this.target;
    }
}

const redirection: IRedirection = new Redirection();
export default redirection;